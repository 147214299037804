<template>
  <footer class="shadow-sm py-2 bg-white">
    <div class="max-w-screen-2xl px-5 mx-auto">
      <div class="text-center md:text-left md:flex items-center justify-between">
        <div class="mb-2 md:mb-0 flex flex-wrap justify-center">
          <a href="https://ooe.arbeiterkammer.at/Impressum.html" target="_blank" rel="noopener" class="mx-2 md:ml-0 mr-4 hocus:text-primary-500">{{ $t("imprint") }}</a>
          <a href="https://ooe.arbeiterkammer.at/Datenschutz.html" target="_blank" rel="noopener" class="mx-2 md:ml-0 mr-4 hocus:text-primary-500">{{ $t("privacy") }}</a>
          <a href="https://www.arbeitenundstudieren.at/" target="_blank" rel="noopener" class="mx-2 md:m-0 whitespace-nowrap hocus:text-primary-500">{{ $t("blog") }}</a>
        </div>
        <div>© Arbeiterkammer Oberösterreich {{ new Date().getFullYear() }}</div>
      </div>
    </div>
  </footer>
</template>