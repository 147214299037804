import { createStore } from 'vuex'
import { form } from './form.js'

const store = createStore({
    modules: {
        form,
    }
})

export default store
