import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Calculator',
        component: () => import('../views/Calculator.vue'),
    },
    {
        path: '/foerderungsmoeglichkeiten',
        name: 'Foerderung',
        component: () => import('../views/Foerderung.vue'),
    },
    {
        path: '/ueber',
        name: 'Introduction',
        component: () => import('../views/Introduction.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
